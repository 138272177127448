import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {IntlProvider} from 'react-intl';

import store from './store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import translations from './translations/i18n-locales';
import flattenMessages from './translations/flattenMessages';
import { getTranslations, getLanguage } from './translations/locale';

ReactDOM.render(
  <IntlProvider locale={getLanguage()} messages={flattenMessages(getTranslations())}>
    <Provider store={store}>
      <App />
    </Provider>
  </IntlProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
