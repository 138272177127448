import { createStyles } from '@material-ui/core';

export const customStyles = () =>
  createStyles({
    blockIndentTop: {
      marginTop: '7px',
      '@media (min-width: 768px)': {
        marginTop: '3%',
        '&:nth-child(4)': {
          marginTop: '3rem'
        },
      },
      '@media (min-width: 1024px)': {
        '&:nth-child(4)': {
          marginTop: '3.5rem'
        },
      },
    },
    blockButton: {
      marginTop: '9%',
      '@media (min-width: 768px)': {
        marginTop: '7.5%',
      },
      '@media (min-width: 1024px)': {
        marginTop: '11%',
      },
      '& .Mui-disabled': {
        backgroundColor: '#b9b9c3',
        color: '#fff'
      },
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      height: '100%',
    },
    input: {
      paddingLeft: '10px',
      height: '40px',
      borderRadius: '5px',
      backgroundColor: '#f7f9fc',
      '&&&:before': {
        borderBottom: 'none'
      },
      '&.MuiInput-underline': {
        '&:after': {
          border: 'none'
        },
        '&.Mui-error': {
          '&:after': {
            border: 'solid 1px #f44336'
          }
        }
      }
    },
    hostedFields: {
      paddingLeft: '1.2rem',
      height: '40px',
      borderRadius: '5px',
      backgroundColor: '#f7f9fc',
    }
  });
