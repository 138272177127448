// React Redux
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

// Material-ui
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';

// Components
import Button from '../../../shared/Button';

// Styled
import { Description, DescriptionTitle } from '../styles';

// Styles
import { customStyles } from './styles';
import { withStyles } from '@material-ui/core/styles';

// Functions
import { getDescription } from '../../../utils/tariff.js';

// Icons
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckIcon from '@material-ui/icons/Check';
import { isRtl } from '../../../translations/locale';


const ServiceContent = ({ amount, classes, discount, price, selectTariff  }) => {
  const tariffDescription = getDescription(useIntl());
  const isFullPrice = !discount; // discount should be 0

  return (
    <Description>
      <Box>
        <DescriptionTitle
          aligning={isRtl() ? 'right' : 'left'}
          direction={isRtl() ? 'row' : 'row-reverse'}
        >
          <span>{':'}</span><FormattedMessage id={`app.ServiceBlock.title`} />
        </DescriptionTitle>

        <Box className={classes.tariffItem} >
          <FormattedMessage id={`app.ServiceLevel.description.time`} />
          <Box>
            <CheckIcon className={classes.CheckIcon} />
          </Box>
        </Box>

        <Box className={classes.tariffItem} >
          <FormattedMessage id={`app.ServiceLevel.description.plan`} />
          <Box>
            <CheckIcon className={classes.CheckIcon} />
          </Box>
        </Box>

        <Box className={classes.tariffItem} >
          <FormattedMessage id={`app.ServiceLevel.description.actions`} />
          <Box>
            <CheckIcon className={classes.CheckIcon} />
          </Box>
        </Box>
      </Box>

      <Grid container spacing={1} className={classes.costContainer}>
        <Grid item className={classes.costBlock}>
          <Box className={classes.costBlockItem} >
            <FormattedMessage id={`app.ServiceBlock.currency`} />
          </Box>
          <Box className={classes.costBlockAmount} >
            {amount}
          </Box>
          <Box className={classes.costBlockItem} >
            <FormattedMessage id={`app.ServiceLevel.cost`} />
          </Box>
        </Grid>
        <Grid item className={classes.notice}>
          {!isFullPrice &&
            <Box><FormattedMessage id={`app.ServiceLevel.notice`} values={{ price }} /></Box>
          }
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.blockButton}  >
        <Button
          className={classes.button}
          onClick={selectTariff.bind(this, amount, tariffDescription)}
          disabled={!amount}
        >
          {isRtl() &&
            <ChevronLeftIcon className={classes.arrow} htmlColor='white' />
          }
          <FormattedMessage id="app.ServiceLevel.button" />
          {!isRtl() &&
            <ChevronRightIcon className={classes.arrow} htmlColor='white' />
          }
        </Button>
      </Grid>
    </Description >
  );
};

export default withStyles(customStyles)(ServiceContent);
