// React Redux
import React from 'react';
import { connect } from 'react-redux';

// Actions
import { PAYMENT_ACTIONS } from '../../store/actions';

// Components
import BackgroundStripes from './components/BackgroundStripes';
import ServiceContent from './components/ServiceContent';

// Styled
import {
  Container,
  Content,
  StripesPositioning,
  Title,
  Header
} from './styles';

// Tariffs
import { getMainColor, color } from './tariffs';

// Functions
import { saveItem } from '../../utils/localStorage.service';
import { FormattedMessage } from 'react-intl';
import { getLanguage } from '../../translations/locale';
import { isRtl } from '../../translations/locale';
class ServiceLevel extends React.PureComponent {

  componentDidMount = () => {
    const { match: {params: { token, uuid }}, getUserInfo} = this.props;
    const tenant_id = new URLSearchParams(location.search).get('tenant_id');

    saveItem(['language', getLanguage()]);
    saveItem(['data', JSON.stringify({token, uuid})]);

    getUserInfo({ token, tenant_id });
  }

  selectTariff = (amount, description) => {
    const { match: {params: {uuid}}, history, setPaymentInfo, userInfo: { phone } } = this.props;
    const { color: trackColor } = getMainColor();

    const paymentInfo = {
      amount,
      description,
      trackColor,
      currency: 'ILS',
      uuid
    };

    setPaymentInfo(paymentInfo);

    phone && history.push('/card');
  };

  back = () => {
    console.log('to go somewhere');
  }

  render() {
    const { priceInfo: { priceWithDiscount, discount, price }} = this.props;

    return (
      <Container background={'#fff'}>
        <Header>
          <StripesPositioning revert={isRtl() ? 'none' : 'scale(-1, 1)'}>
            <BackgroundStripes width={innerWidth}/>
          </StripesPositioning>
          <Title aligning={isRtl() ? 'flex-start' : 'flex-end'} >
            <FormattedMessage id="app.Payment.header" />
          </Title>
        </Header>
        <Content maincolor={!color ? '#fff' : color}>
          <ServiceContent
            amount={priceWithDiscount}
            discount={discount}
            price={price}
            selectTariff={this.selectTariff}
          />
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return{
    paymentInfo: state.payment.paymentInfo,
    userInfo: state.payment.userInfo,
    priceInfo: state.payment.priceInfo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPaymentInfo: (paymentInfo) =>
      dispatch({
        type: PAYMENT_ACTIONS.setPaymentInfo,
        payload: paymentInfo
      }),
    getUserInfo: (token) =>
      dispatch({
        type: PAYMENT_ACTIONS.getUserInfoRequest,
        payload: token
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceLevel);
