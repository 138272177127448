import { createStyles } from '@material-ui/core';
import { isRtl } from '../../translations/locale';

const tariffTypeBorder = isRtl() ?
  { borderTopLeftRadius: '30px', borderBottomLeftRadius: '30px', } :
  { borderTopRightRadius: '30px', borderBottomRightRadius: '30px', };
const checkIconMargin = isRtl() ? { marginLeft: '4px', } : { marginRight: '4px', };

export const customStyles = (theme) =>
  createStyles({
    container: {
      height: '105vh',
      width: '100%',
      background: '#ffffff',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      [theme.breakpoints.up(768)]: {
        paddingLeft: '1.2rem',
        paddingRight: '1.2rem'
      },
      [theme.breakpoints.up(1024)]: {
        paddingLeft: '1rem',
        paddingRight: '1rem'
      }
    },
    title: {
      fontFamily: 'LieblingProBold',
      fontSize: '1.75rem',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: '0.4px',
      textAlign:  isRtl() ? 'right' : 'left',
      color: '#272735',
      paddingTop: '5px',
      [theme.breakpoints.up(1024)]: {
        marginTop: '2rem'
      }
    },
    cardType: {
      fontFamily: 'LieblingProBold',
      fontSize: '1.55rem',
      lineHeight: '1.04',
      letterSpacing: '0.16px',
      textAlign: isRtl() ? 'right' : 'left',
      color: '#95959d',
      margin: '10px 0'
    },
    CheckIcon: {
      ...checkIconMargin,
      fontSize: 'inherit',
      color: 'inherit'
    },
    blockButton: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      boxSizing: 'border-box',
      marginTop: '7%',
      marginBottom: '50px'
    },
    tariffType: {
      ...tariffTypeBorder,
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      // @ts-ignore
      background: props => props && props?.paymentInfo?.trackColor,
      writingMode: 'vertical-rl',
      textOrientation: 'mixed',
      fontFamily: 'LieblingProBold',
      fontSize: '1.5rem',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1',
      letterSpacing: '0.17px',
      textAlign: 'right',
      color: '#f7f9fc'
    },
    rotateBlock: {
      transform: 'rotate(180deg)',
      paddingBottom: '0.2rem'
    },
    sign: {
      width: '26px',
      height: '100%',
      fontFamily: 'Heebo',
      fontSize: '1.5rem',
      lineHeight: 1.19,
      marginRight: '5px'
    },
    tariffItem: {
      display: 'flex',
      flexDirection: isRtl() ? 'row' : 'row-reverse',
      justifyContent: 'flex-end',
      fontSize: '0.9rem',
      lineHeight: 1.5,
      textAlign: isRtl() ? 'right' : 'left',
      color: '#95959d',
      marginTop: '0.7rem',
      fontFamily: 'Heebo'
    },
    case: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '11px',
      background: `#f5f5f5`,
      minHeight: '50px',
      marginTop: '14px',
      marginBottom: '17px',
      fontFamily: 'LieblingProRegular',
      [theme.breakpoints.up(768)]: {
        marginTop: '0.7rem',
        marginBottom: '1rem'
      }
    },
    description: {
      display: 'flex',
      flexDirection: isRtl() ? 'row' : 'row-reverse',
      marginBottom: '8%',
      marginTop: '10%',
      height: '30%',
      '& $cardType + $tariffItem': {
        marginTop: '1.5rem'
      },
      [theme.breakpoints.up(376)]: {
        height: '250px'
      },
      [theme.breakpoints.up(768)]: {
        height: '15rem',
        marginTop: '2.7rem',
        marginBottom: '2.5rem'
      },
      [theme.breakpoints.up(1024)]: {
        height: '13.8rem'
      },
      [theme.breakpoints.down(376)]: {
        height: '240px'
      },
      [theme.breakpoints.down(321)]: {
        height: '200px'
      }
    },
    receipt: {
      fontFamily: 'Heebo',
      fontSize: '0.8rem',
      lineHeight: 1.5,
      textAlign: isRtl() ? 'right' : 'left',
      color: '#95959d'
    },
    caseTitle: {
      fontFamily: 'Heebo',
      fontSize: '0.8rem',
      fontWeight: 'bold',
      lineHeight: 1.5,
      textAlign: isRtl() ? 'right' : 'left',
      color: '#0C0C0C'
    },
    link: {
      fontFamily: 'LieblingProBold',
      fontSize: '1.1rem',
      textAlign: isRtl() ? 'right' : 'left',
      letterSpacing: '0.11px',
      // color:'#272735',
      color: '#fff',
      marginBottom: '16px',
      [theme.breakpoints.up(810)]: {
        marginBottom: '1.5rem'
      }
    },
    questions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    questionsButton: {
      borderRadius: '11px',
      background: `#f5f5f5`,
      minHeight: '4rem',
      marginTop: '14px',
      padding: '0px',
      fontFamily: 'Heebo',
      fontSize: '0.9rem',
      lineHeight: 1.71,
      color:' #72727e',
      textTransform: 'none',
    }
  });
