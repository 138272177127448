import styled from 'styled-components';

export const Container = styled.div`
  height: 105vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${props => props.background};
`;
export const Content = styled.div`
  z-index: 1;
  height: 75vh;
  width: 100%;
  flex-grow: 12;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  background: ${props => props.maincolor};
`;
export const Description = styled.div`
  padding: 8% 10%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const DescriptionTitle = styled.div`
  margin-${props => props.aligning}: 6%;
  padding-bottom: 15px;
  text-align: ${props => props.aligning};
  color: #fff;
  display: flex;
  justify-content: flex-end;
  flex-direction: ${props => props.direction};
`;

export const Header = styled.div`
  position: relative;
  padding: ${props => props.padding};
`;
export const Title = styled.p`
  font-family: 'LieblingProBold';
  font-size: 1.75rem;
  text-align: right;
  color:#272735;
  display: flex;
  justify-content: ${props => props.aligning};
  flex-direction: row-reverse;
  padding: 3%;
  padding-top: 30%;
  margin: 0;
`;

export const StripesPositioning = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${props => props.revert};
`;